body {
  margin: 0;
  height: 100vh;
  display: flex;
  background-color: #f2d300;
  overflow: hidden;
}
svg {
  position: absolute;
}
.status-code {
  font-size: 70vw;
  font-weight: bold;
  font-family: Courier, monospace;
  dominant-baseline: central;
  text-anchor: middle;
}
#background {
  clip-path: url(#status-code);
  background-color: #191416;
  height: 100%;
  width: 100%;
}
.swarm {
  position: absolute;
  opacity: 1;
  transition: opacity 0.25s;
}
#background[unloaded] .swarm {
  opacity: 0;
}
.bees-wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
}
.bees {
  display: block;
  transition: transform 0.5s linear;
}
